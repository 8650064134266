import React, { useState } from 'react';
import ImageSlider from './image_slider';
import { signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { auth } from '../firebase';

// 이미지 import
import dlogLogo from '../assets/images/splash_dlog_logo.svg';
import anim01 from '../assets/images/anim_01.png';
import anim02 from '../assets/images/anim_02.png';
import anim03 from '../assets/images/anim_03.png';
import anim04 from '../assets/images/anim_04.png';
import anim05 from '../assets/images/anim_05.png';
import appleIcon from '../assets/images/logo_apple_white.svg';
import googleIcon from '../assets/images/logo_google.svg';

// 이미지 URL 배열
const imageUrls = [anim01, anim02, anim03, anim04, anim05];

// 웹용 시작하기 페이지 컴포넌트
const WebSignInPage: React.FC = () => {
    const [isBlocking, setIsBlocking] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /// 인증 성공 시 리다이렉션 처리
    const handleAuthSuccess = async (user: any) => {
        try {
            const redirectUrl = `/splashPage`;
            window.location.href = redirectUrl;
        } catch (error) {
            console.error('인증 처리 에러:', error);
            setIsLoading(false);
            setIsBlocking(false);
        }
    };

    const handleSocialLogin = async (provider: 'apple' | 'google'): Promise<void> => {
        if (isBlocking) return;

        window.dataLayer.push({
            'event': 'social_login_click',
            'provider': provider.toUpperCase(),
            'entry_point': 'web_sign_in_page',
        });

        try {
            setIsBlocking(true);
            setIsLoading(true);

            const authProvider = provider === 'apple'
                ? (() => {
                    const provider = new OAuthProvider('apple.com');
                    provider.addScope('email');
                    provider.addScope('name');
                    provider.setCustomParameters({ prompt: "consent" });
                    return provider;
                })()
                : (() => {
                    const provider = new GoogleAuthProvider();
                    provider.addScope('email');
                    provider.addScope('profile');
                    provider.setCustomParameters({ prompt: 'select_account' });
                    return provider;
                })();

            const result = await signInWithPopup(auth, authProvider);
            await handleAuthSuccess(result.user);
        } catch (e) {
            console.error('소셜 로그인 에러:', e);
            setIsBlocking(false);
            setIsLoading(false);
        }
    };

    const handleDemoLogin = () => {
        window.dataLayer.push({
            'event': 'social_login_click',
            'provider': 'anonymous',
            'entry_point': 'web_sign_in_page',
        });

        window.location.href = '/splashPage?demo=true';
    };

    return (
        <div className="min-h-screen bg-[#F8F8F8] flex justify-center">
            <div className="w-full bg-white max-w-[500px] relative overflow-hidden">
                <div className="pt-[50px] px-[30px] pb-[30px]">
                    <div className="flex items-end mb-4">
                        <img
                            src={dlogLogo}
                            alt="DLog 로고"
                            className="w-32 h-8"
                        />
                        <span className="ml-2 text-gray-500 text-sm">Web Beta</span>
                    </div>
                    <h1 className="text-xl font-semibold text-[#444444]">
                        언제 어디서나 나의 일상을<br />
                        빠르게 기록해 보세요
                    </h1>
                </div>

                <div className="relative w-full">
                    <ImageSlider images={imageUrls} />
                </div>

                <div className="fixed bottom-0 left-0 right-0 pt-[10px] px-[30px] pb-[30px] max-w-[500px] bg-white mx-auto z-20">
                    <button
                        onClick={() => handleSocialLogin('apple')}
                        disabled={isBlocking}
                        className="w-full mb-4 py-4 px-6 bg-black text-white rounded-full flex items-center justify-center gap-2 hover:bg-gray-900 transition-colors"
                    >
                        {isLoading ? (
                            <div className="w-6 h-6 animate-spin rounded-full border-2 border-[#00E283] border-t-transparent" />
                        ) : (
                            <>
                                <img src={appleIcon} alt="Apple" className="w-6 h-6" />
                                <span className="font-medium">Apple로 시작하기</span>
                            </>
                        )}
                    </button>

                    <button
                        onClick={() => handleSocialLogin('google')}
                        disabled={isBlocking}
                        className="w-full mb-4 py-4 px-6 bg-white text-black rounded-full flex items-center justify-center gap-2 border border-gray-200 hover:bg-gray-50 transition-colors"
                    >
                        {isLoading ? (
                            <div className="w-6 h-6 animate-spin rounded-full border-2 border-[#00E283] border-t-transparent" />
                        ) : (
                            <>
                                <img src={googleIcon} alt="Google" className="w-6 h-6" />
                                <span className="font-medium">Google로 시작하기</span>
                            </>
                        )}
                    </button>

                    <button
                        onClick={handleDemoLogin}
                        disabled={isBlocking}
                        className="w-full py-2 text-[#BBB] hover:text-gray-700 transition-colors font-medium"
                    >
                        디로그 미리보기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WebSignInPage;