import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Swiper 스타일
import 'swiper/css';

interface ImageSliderProps {
    /** 슬라이더에 표시할 이미지 URL 배열 */
    images: string[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
    return (
        <div className="relative">
            <Swiper
                modules={[Autoplay]}
                grabCursor={false}
                centeredSlides={true}
                slidesPerView="auto"
                loop={true}
                autoplay={{
                    delay: 1200,
                    disableOnInteraction: false,
                }}
                spaceBetween={35}
                className="!overflow-visible"
            >
                {images.map((imageUrl, index) => (
                    <SwiperSlide
                        key={index}
                        className="!w-[61%] max-w-[245px] short:max-w-[210px]"
                    >
                        <div className="px-[5px]">
                            <div className="aspect-[235/416] relative">
                                <img
                                    src={imageUrl}
                                    alt={`슬라이드 ${index + 1}`}
                                    decoding="async"
                                    className="absolute inset-0 w-full h-full object-cover rounded-lg"
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white to-transparent z-10" />
        </div>
    );
};

export default ImageSlider;