import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD6eK0TR6KN8v18MVXAXXwRrPJSs7V-Fgw",
    authDomain: "dlog-9188a.firebaseapp.com",
    projectId: "dlog-9188a",
    storageBucket: "dlog-9188a.appspot.com",
    messagingSenderId: "730475296846",
    appId: "1:730475296846:web:ff53c72eb9a460d21aeb1f",
    measurementId: "G-5D0ZV8YRDX"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); 