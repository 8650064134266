import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import WebSignInPage from './components/web_sign_in_page';

// dataLayer 타입 정의
declare global {
  interface Window {
    dataLayer: any[];
  }
}

// dataLayer 초기화
window.dataLayer = window.dataLayer || [];

// 모바일 기기 체크 및 리다이렉션을 처리하는 컴포넌트
const AppWrapper: React.FC = () => {
  useEffect(() => {
    const checkMobileAndRedirect = async () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = userAgent.includes('android');
      const isIOS = userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod');
      const isMobile = userAgent.includes('mobile') || userAgent.includes('tablet');

      // 방문 이벤트 트래킹
      window.dataLayer.push({
        'event': 'page_view',
        'platform': isAndroid ? 'android' : isIOS ? 'ios' : 'web',
        'userAgent': userAgent,
        'page_path': window.location.pathname,
        'page_url': window.location.href,
      });

      if ((isAndroid || isIOS) && isMobile) {
        const storeUrl = isAndroid
          ? 'https://play.google.com/store/apps/details?id=com.softlunch.dlog'
          : 'https://apps.apple.com/kr/app/apple-store-id6451177080';

        // 앱 실행 시도 트래킹
        window.dataLayer.push({
          'event': 'app_open_attempt',
          'platform': isAndroid ? 'android' : 'ios',
        });

        // UI가 렌더링 될 수 있도록 약간의 지연 후 리다이렉트
        setTimeout(() => {
          window.location.href = storeUrl;
        }, 100);
      }
    };

    // 페이지 진입 시 체크 실행
    if (window.location.pathname === '/') {
      checkMobileAndRedirect();
    }
  }, []);

  return <WebSignInPage />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWrapper />
    </BrowserRouter>
  </React.StrictMode>
);